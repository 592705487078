@media only screen and (max-width: 767px) {
    .col .card {
          align-items: center !important;
      }
  } 
 .card {
    transition: all .2s ease-in-out; 
}

 .card:hover {
    transform: scale(1.1); 
}